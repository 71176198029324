import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push } from "firebase/database";
import "./BusinessRequirement.css";

// Firebase configuration (ensure these are set in your .env file)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const BusinessRequirementForm = () => {
  const [formData, setFormData] = useState({
    businessName: "",
    projectDomain: "",
    description: "",
    yourName: "",
    yourContact: "",
    industry: "",
    budget: "",
    OutcomeExpected: "",
    otherinfo: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const database = getDatabase(app);
    const requirementsRef = ref(database, "business_requirements");
    push(requirementsRef, formData)
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => {
        console.error("Error saving data: ", error);
      });
  };

  return (
    <div className="Main_container">
    <div className="form-container">
      <h2>Share Your Project Requirements</h2>
      <p>We'll Connect You With Agencies Best For You</p>
      <br></br>
      {submitted ? (
        <div className="thank-you-message">
          <h3>Thank you for your response!</h3>
          <p>We will get back to you soon</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="business-requirement-form">
          <label htmlFor="yourName">Your Name:</label>
          <input
            type="text"
            id="yourName"
            name="yourName"
            value={formData.yourName}
            onChange={handleChange}
            required
          />

          <label htmlFor="yourContact">Your Contact (Phone and Email):</label>
          <input
            type="text"
            id="yourContact"
            name="yourContact"
            value={formData.yourContact}
            onChange={handleChange}
            required
          />

          <label htmlFor="businessName">Business Name:</label>
          <input
            type="text"
            id="businessName"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            required
          />

          <label htmlFor="industry">Industry you operate in</label>
          <input
            type="text"
            id="industry"
            name="industry"
            placeholder="Ex. Healthcare, Fashion etc."
            value={formData.industry}
            onChange={handleChange}
            required
          />

          <label htmlFor="projectDomain">Project Domain:</label>
          <select
            id="projectDomain"
            name="projectDomain"
            value={formData.projectDomain}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select a Domain
            </option>
            <option value="Marketing">Marketing</option>
            <option value="Design">Design</option>
            <option value="Tech&IT">Tech and IT</option>
            <option value="Legal">Legal Services</option>
            <option value="FinancialAuditing">
              Financial and Auditing services
            </option>
            <option value="HRrelated">HR and related services</option>
            <option value="Other">others</option>
          </select>

          

          <label htmlFor="budget">
            Budget (Ex 1.25Lakhs/Month, 2 Lakhs in total)
          </label>
          <input
            type="text"
            id="budget"
            name="budget"
            value={formData.budget}
            onChange={handleChange}
            required
          />

          <label htmlFor="description">Requirement Description:</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Describe your requirments so that we serve you the BEST"
          />

          <label htmlFor="OutcomeExpected">Outcome Expected :</label>
          <textarea
            id="OutcomeExpected"
            name="OutcomeExpected"
            value={formData.OutcomeExpected}
            onChange={handleChange}
            placeholder=""
          />

          <label htmlFor="otherinfo">Other Information (If any) :</label>
          <textarea
            id="otherinfo"
            name="otherinfo"
            value={formData.otherinfo}
            onChange={handleChange}
            placeholder=""
          />

          <button type="submit" className="cta-button">
            Submit Your Requirement
          </button>
        </form>
      )}
    </div>
    </div>
  );
};

export default BusinessRequirementForm;
