import React from "react";
import { useState } from "react";
import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";
import Modal from "../components/modal";
import Form from "../components/waitlist";
import { partners } from "./partnersData";
import Navbar from "../components/navbar";
import Mark from "../components/mark";
import Card from "../components/card";
import Accordion from "../components/accordion";
import Includes from "../components/includes";
import Excludes from "../components/excludes";
import Review from "../components/review";
import Article from "../components/article";
import FAQ from "../components/faq";
import "./agencyhome.css";

const AgencyHome = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="agencyhome-container1">
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <Form onSuccess={closeModal} />
      </Modal>
      <Helmet>
        <title>AgencyMart</title>
        <meta property="og:title" content="AgencyMart" />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name"></Navbar>
      <section className="agencyhome-section10">
        <div className="agencyhome-hero">
          <div className="agencyhome-content1">
            <main className="agencyhome-main1">
              <header className="agencyhome-header10">
                <h1 className="agencyhome-heading10">
                  Empowering Agencies to Connect with Top Businesses
                </h1>
                <span className="agencyhome-caption1">
                  Join us to discover trusted businesses seeking top-tier agency
                  partners. Benefit from our flat fee model, expert support, and
                  showcase your expertise to a wide audience.
                </span>
              </header>
              <div className="agencyhome-buttons1">
                <a href="/agencyonboarding">
                  <div className="agencyhome-get-started1 button open-modal-button">
                    <button
                      className="agencyhome-text10"
                      // onClick={openModal}
                    >
                      Join AgencyMart For Free
                    </button>
                  </div>
                </a>
                <a href="https://www.linkedin.com/in/harsh-agarwal-a27655204/">
                  <div className="agencyhome-get-started2 button">
                    <a className="agencyhome-text11">Talk to Founders</a>
                  </div>
                </a>
              </div>
            </main>
            <div className="agencyhome-highlight">
              <div className="agencyhome-avatars">
                <img
                  alt="image"
                  src="/Icons/zuruai.png"
                  className="agencyhome-image10 avatar"
                />
                <img
                  alt="image"
                  src="/Icons/ironasylum.png"
                  className="agencyhome-image11 avatar"
                />
                <img
                  alt="image"
                  src="/Icons/setu.png"
                  className="agencyhome-image12 avatar"
                />
              </div>
              <label className="agencyhome-caption2">
                AgencyMart is Loved by Businesses and Top Agencies alike.
              </label>
            </div>
          </div>
          <div className="agencyhome-image13">
            <img
              alt="image"
              src="/SectionImages/herosection.png"
              className="agencyhome-image14"
            />
          </div>
          <div className="agencyhome-image15">
            <img
              alt="image"
              src="/SectionImages/herosection.png"
              className="agencyhome-image16"
            />
          </div>
        </div>
      </section>
      <section className="agencyhome-section11">
        <h2 className="agencyhome-text12">
          Trusted by Leading Agencies and Growing Businesses
        </h2>
        <div className="agencyhome-features1">
          <header className="feature feature-active agencyhome-feature1">
            <h3 className="agencyhome-text13">
              Partner with Confidence and Expand Your Reach
            </h3>
            {/* <p className="agencyhome-text14">Doloremque laudantium</p> */}
          </header>
          {/* <header className="feature agencyhome-feature2">
            <h3 className="agencyhome-text15">Chronic Care</h3>
            <p className="agencyhome-text16">Doloremque laudantium</p>
          </header>
          <header className="feature agencyhome-feature3">
            <h3 className="agencyhome-text17">Mental Health</h3>
            <p className="agencyhome-text18">Doloremque laudantium</p>
          </header> */}
        </div>
        <div className="agencyhome-note1">
          <div className="agencyhome-content2">
            <main className="agencyhome-main2">
              {/* <h2 className="agencyhome-heading11">
                Accessing this Medicare benefit is easy
              </h2> */}
              <p className="agencyhome-paragraph1">
                <span>
                  At AgencyMart, we empower agencies to connect with businesses
                  that need their specialized services. Our platform is designed
                  to streamline the process, ensuring you can focus on what you
                  do best—delivering exceptional results.
                </span>
                <br></br>
                <br></br>
                <p>Our platform offers:</p>
                <ul>
                  <li>
                    Access to a wide range of businesses actively seeking agency
                    partnerships.
                  </li>
                  <li>Expert assistance in crafting pitches and proposals.</li>
                  <li>
                    Dedicated support from field experts to evaluate and enhance
                    your submissions.
                  </li>
                </ul>
                <br></br>
              </p>
            </main>
            <div className="agencyhome-explore-more">
              <a
                className="agencyhome-text24"
                href="https://www.linkedin.com/company/agency-mart/"
              >
                Explore more -&gt;
              </a>
            </div>
          </div>
          {/* <div className="agencyhome-image17">
            <img
              alt="image"
              src="/SectionImages/group%201490-1200w.png"
              className="agencyhome-image18"
            />
          </div> */}
        </div>
      </section>
      <section className="agencyhome-section12">
        {/* <header className="agencyhome-header11">
          <h2 className="agencyhome-text25">Why do you need a mobile medical app?</h2>
          <span className="agencyhome-text26">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </span>
        </header> */}
        {/* <section className="agencyhome-note2">
          <div className="agencyhome-image19">
            <img
              alt="image"
              src="/SectionImages/group%201428-1200w.png"
              className="agencyhome-image20"
            />
          </div>
          <div className="agencyhome-content3">
            <div className="agencyhome-main3">
              <div className="agencyhome-caption3">
                <span className="section-head">Tempor incididunt</span>
              </div>
              <div className="agencyhome-heading12">
                <h2 className="section-heading">
                  We provide compassionate care from start to finish.
                </h2>
                <p className="section-description">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae.
                </p>
              </div>
            </div>
            <div className="agencyhome-get-started3 button">
              <span className="agencyhome-text27">Get started</span>
            </div>
          </div>
        </section> */}
        {/* <section className="agencyhome-note3">
          <div className="agencyhome-image21">
            <img
              alt="image"
              src="/SectionImages/group%201449-1200w.png"
              className="agencyhome-image22"
            />
          </div>
          <div className="agencyhome-content4">
            <main className="agencyhome-main4">
              <header className="agencyhome-caption4">
                <span className="agencyhome-section14 section-head">
                  Tempor incididunt
                </span>
              </header>
              <main className="agencyhome-heading14">
                <header className="agencyhome-header12">
                  <h2 className="section-heading">
                    Great care, wherever you are
                  </h2>
                  <p className="section-description">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium.
                  </p>
                </header>
                <div className="agencyhome-checkmarks">
                  <Mark></Mark>
                  <Mark label="Quis nostrud exercitation ullamco"></Mark>
                  <Mark label="Reprehenderit qui in ea voluptate velit"></Mark>
                </div>
              </main>
            </main>
            <div className="agencyhome-get-started4 button">
              <span className="agencyhome-text28">Get started</span>
            </div>
          </div>
        </section> */}
      </section>
      <section className="agencyhome-section15">
        <header className="agencyhome-header13">
          <header className="agencyhome-left1">
            <span className="section-head">How it Works ?</span>
            <h2 className="section-heading">
              <span>Streamlined Process to </span>
              <br></br>
              <span>Boost Your Agency’s Growth</span>
            </h2>
          </header>
          <div className="agencyhome-right1">
            <p className="agencyhome-paragraph4 section-description">
              Join AgencyMart and follow our simple steps to connect with
              businesses that need your expertise. Our platform ensures a
              transparent and efficient partnership process.
            </p>
          </div>
        </header>
        <main className="agencyhome-cards1">
          <Card
            rootClassName="cardroot-class-name"
            header="Posting Requirement"
            description="Our Experts help businesses draft requirements in the best way possible."
          ></Card>
          <Card
            icon="/Icons/group%201314-200h.png"
            rootClassName="cardroot-class-name1"
            header="Agency Pitching"
            description="Agencies like you Pitch to show interest and provide their most relevant work to impress the business"
          ></Card>
          <Card
            icon="/Icons/group%201317-200h.png"
            rootClassName="cardroot-class-name2"
            header="Connecting the dots"
            description="Our team will help you connect the business which shortlists you based on the inital pitch"
          ></Card>
        </main>
      </section>
      <section className="agencyhome-section17">
        {/* <div className="agencyhome-note4">
          <div className="agencyhome-image23">
            <img
              alt="image"
              src="/SectionImages/iphone%2014%20pro%20max-1200w.png"
              className="agencyhome-image24"
            />
          </div>
          <div className="agencyhome-content5">
            <div className="agencyhome-caption5">
              <span className="section-head">Tempor incididunt</span>
            </div>
            <div className="agencyhome-heading17">
              <div className="agencyhome-header14">
                <h2 className="section-heading">
                  Tips to get care, answers and advice faster
                </h2>
              </div>
              <Accordion rootClassName="accordionroot-class-name"></Accordion>
            </div>
          </div>
        </div> */}
      </section>
      <section className="agencyhome-section19">
        <div className="agencyhome-cube1">
          <div className="agencyhome-top1 side"></div>
          <div className="agencyhome-front1 side"></div>
          <div className="agencyhome-left2 side"></div>
        </div>
        <main className="agencyhome-banner">
          <div className="agencyhome-header15">
            <h2 className="section-heading">
              AgencyMart makes outsourcing trustworthy
            </h2>
            <p className="agencyhome-description1 section-description">
              Your Trust is our Top Priority
            </p>
          </div>
          <div className="agencyhome-buttons2">
            <a href="/agencyonboarding/">
              <div className="agencyhome-get-started5 button button open-modal-button">
                <span className="agencyhome-text32">Join AgencyMart</span>
              </div>
            </a>
            <a href="https://www.linkedin.com/in/harsh-agarwal-a27655204/">
              <div className="agencyhome-book-demo button">
                <a className="agencyhome-text33">Talk to Founders</a>
              </div>
            </a>
          </div>
        </main>
      </section>
      <section className="agencyhome-section20">
        <div className="agencyhome-cube2">
          <div className="agencyhome-top2 side"></div>
          <div className="agencyhome-front2 side"></div>
          <div className="agencyhome-left3 side"></div>
        </div>
        <main className="agencyhome-pricing1">
          <header className="agencyhome-header16">
            <header className="agencyhome-left4">
              <span className="section-head">Pricing</span>
              <h2 className="section-heading agencyhome-heading20">
                Transparent Revenue Model
              </h2>
            </header>
            <div className="agencyhome-right2">
              <p className="agencyhome-paragraph5 section-description">
                Agencies pay a flat fee for each pitch they submit, ensuring no
                hidden costs and predictable pitch budgeting.<br></br>
              </p>
            </div>
          </header>
          {/* <div className="agencyhome-plans-container">
            <div className="agencyhome-switch1">
              <div className="switch">
                <label className="agencyhome-text34">Monthly</label>
              </div>
              <div className="agencyhome-switch3 switch">
                <label className="agencyhome-text35">Yearly</label>
              </div>
            </div>
            <main className="agencyhome-plans">
              <div className="agencyhome-plan1">
                <div className="agencyhome-details1">
                  <div className="agencyhome-header17">
                    <label className="agencyhome-name1">Basic</label>
                    <div className="agencyhome-pricing2">
                      <h1 className="agencyhome-price1">$9</h1>
                      <span className="agencyhome-duration1">/mo</span>
                    </div>
                  </div>
                  <p className="agencyhome-description2">
                    Good for sed quia consequuntur magni dolores eos qui
                    ratione.
                  </p>
                </div>
                <div className="agencyhome-buy-details1">
                  <div className="agencyhome-buy1 button">
                    <span className="agencyhome-text36">
                      <span>Start Basic</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="agencyhome-features2">
                    <span className="agencyhome-heading21">You will get</span>
                    <div className="agencyhome-list1">
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                    </div>
                  </div>
                </div>
              </div>
              <div className="agencyhome-plan2">
                <div className="agencyhome-details2">
                  <div className="agencyhome-header18">
                    <label className="agencyhome-name2">Professional</label>
                    <div className="agencyhome-pricing3">
                      <h1 className="agencyhome-price2">$15</h1>
                      <span className="agencyhome-duration2">/mo</span>
                    </div>
                  </div>
                  <p className="agencyhome-description3">
                    Good for sed quia consequuntur magni dolores eos qui
                    ratione.
                  </p>
                </div>
                <div className="agencyhome-buy-details2">
                  <div className="agencyhome-buy2 button">
                    <span className="agencyhome-text39">
                      <span>Start Professional</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="agencyhome-features3">
                    <span className="agencyhome-heading22">You will get</span>
                    <div className="agencyhome-list2">
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                    </div>
                  </div>
                </div>
              </div>
              <div className="agencyhome-plan3">
                <div className="agencyhome-details3">
                  <div className="agencyhome-header19">
                    <label className="agencyhome-name3">Enterprise</label>
                    <div className="agencyhome-pricing4">
                      <span className="agencyhome-price3">$99</span>
                      <span className="agencyhome-duration3">/mo</span>
                    </div>
                  </div>
                  <p className="agencyhome-description4">
                    Good for sed quia consequuntur magni dolores eos qui
                    ratione.
                  </p>
                </div>
                <div className="agencyhome-buy-details3">
                  <div className="agencyhome-buy3 button">
                    <span className="agencyhome-text42">
                      <span>Start Enterprise</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="agencyhome-features4">
                    <span className="agencyhome-heading23">You will get</span>
                    <div className="agencyhome-list3">
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div> */}
        </main>
        <div className="agencyhome-help">
          <span className="agencyhome-text45">
            <span>Need any help?</span>
            <br></br>
          </span>
          <div className="agencyhome-contact-support">
            <a
              className="agencyhome-text48"
              href="mailto:contact@agencymart.info"
            >
              Contact support -&gt;
            </a>
          </div>
        </div>
      </section>
      <section className="agencyhome-section22">
        {/* <header className="agencyhome-header20">
          <header className="agencyhome-left5">
            <span className="section-head">Tempor incididunt</span>
            <h2 className="agencyhome-heading24 section-heading">
              What users say about us
            </h2>
          </header>
          <div className="agencyhome-right3">
            <p className="agencyhome-paragraph6 section-description">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam.
            </p>
          </div>
        </header>
        <main className="agencyhome-cards2">
          <div className="agencyhome-container2">
            <Review rootClassName="reviewroot-class-name"></Review>
            <Review
              quote="“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.\u2028\u2028Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
          <div className="agencyhome-container3">
            <Review
              quote="“Illum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”"
              rootClassName="reviewroot-class-name"
            ></Review>
            <Review
              quote="“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
          <div className="agencyhome-container4">
            <Review
              quote="“Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.”"
              rootClassName="reviewroot-class-name"
            ></Review>
            <Review
              quote="“Doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
        </main>
        <div className="agencyhome-view-more">
          <p className="agencyhome-text49">View more</p>
        </div> */}
      </section>
      <section className="agencyhome-section24">
        {/* <header className="agencyhome-header21">
          <span className="section-head">Articles about us</span>
          <h2 className="agencyhome-heading25 section-heading">
            We’re the app on everyone’s lips
          </h2>
        </header>
        <main className="agencyhome-cards3">
          <Article rootClassName="articleroot-class-name"></Article>
          <Article
            header="techeu"
            specialHeader="eu"
            rootClassName="articleroot-class-name"
          ></Article>
          <Article
            header="sifted/"
            rootClassName="articleroot-class-name"
          ></Article>
        </main> */}
      </section>
      <section className="agencyhome-section-partners">
        <header className="agencyhome-header-partners">
          <span className="section-head">Our Partners</span>
          <h2 className="agencyhome-heading-partners section-heading">
            Trusted by Leading Agencies
          </h2>
        </header>
        <main className="agencyhome-partners-container">
          <div className="agencyhome-partners-scroll">
            {/* First set of partner logos */}
            {partners.map((partner, index) => (
              <div key={`partner-${index}`} className="agencyhome-partner-logo">
                {partner.url ? (
                  <a
                    href={partner.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={partner.logo}
                      alt={`${partner.name} Logo`}
                      loading="lazy"
                    />
                  </a>
                ) : (
                  <img
                    src={partner.logo}
                    alt={`${partner.name} Logo`}
                    loading="lazy"
                  />
                )}
              </div>
            ))}
            {/* Duplicate set of partner logos for seamless scrolling */}
            {partners.map((partner, index) => (
              <div
                key={`partner-duplicate-${index}`}
                className="agencyhome-partner-logo"
              >
                {partner.url ? (
                  <a
                    href={partner.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={partner.logo}
                      alt={`${partner.name} Logo`}
                      loading="lazy"
                    />
                  </a>
                ) : (
                  <img
                    src={partner.logo}
                    alt={`${partner.name} Logo`}
                    loading="lazy"
                  />
                )}
              </div>
            ))}
          </div>
        </main>
      </section>

      <section className="agencyhome-section26">
        {/* <header className="agencyhome-header22">
          <span className="section-head">FAQ</span>
          <h2 className="agencyhome-heading26 section-heading">
            Frequently asked questions
          </h2>
        </header>
        <main className="agencyhome-accordion">
          <FAQ rootClassName="fa-qroot-class-name"></FAQ>
        </main> */}
      </section>
      <section className="agencyhome-section">
        {/* <main className="agencyhome-content6">
          <header className="agencyhome-header23">
            <h2 className="agencyhome-heading27 section-heading">
              Stop searching online for medications and use AgencyMart app!
            </h2>
            <div className="agencyhome-buttons3">
              <div className="agencyhome-ios button">
                <img
                  alt="image"
                  src="/Icons/apple-200w.png"
                  className="agencyhome-icon1"
                />
                <span className="agencyhome-text50">Download for iOS</span>
              </div>
              <div className="agencyhome-android button">
                <img
                  alt="image"
                  src="/Icons/android-200h.png"
                  className="agencyhome-icon2"
                />
                <span className="agencyhome-text51">Download for Android</span>
              </div>
            </div>
          </header>
          <img
            alt="image"
            src="/SectionImages/group%201505-1200w.png"
            className="agencyhome-image25"
          />
        </main> */}
      </section>
      <footer className="agencyhome-footer">
        <div className="agencyhome-content7">
          <main className="agencyhome-main-content">
            <div className="agencyhome-content8">
              <header className="agencyhome-main5">
                <div className="agencyhome-header24">
                  <img
                    alt="image"
                    src="/Branding/darklogo.png"
                    className="agencyhome-branding"
                  />
                  <span className="agencyhome-text52">
                    Connect with us here on our Socials
                  </span>
                </div>
                <div className="agencyhome-socials">
                  <a
                    href="https://www.linkedin.com/company/agency-mart/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="agencyhome-link1"
                  >
                    <img
                      alt="image"
                      src="/Icons/linkedin-200h.png"
                      className="social"
                    />
                  </a>
                  {/* <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="agencyhome-link2"
                  >
                    <img
                      alt="image"
                      src="/Icons/instagram-200h.png"
                      className="social"
                    />
                  </a> */}
                  <a
                    href="https://twitter.com/CareerWtPrakhar"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="agencyhome-link3"
                  >
                    <img
                      alt="image"
                      src="/Icons/twitter-200h.png"
                      className="social"
                    />
                  </a>
                </div>
              </header>
              <header className="agencyhome-categories">
                {/* <div className="agencyhome-category1">
                  <div className="agencyhome-header25">
                    <span className="footer-header">Solutions</span>
                  </div>
                  <div className="agencyhome-links1">
                    <span className="footer-link">Responsive Web Design</span>
                    <span className="footer-link">Responsive Prototypes</span>
                    <span className="footer-link">Design to Code</span>
                    <span className="footer-link">Static Website Builder</span>
                    <span className="footer-link">
                      Static Website Generator
                    </span>
                  </div>
                </div> */}
                <div className="agencyhome-category2">
                  <div className="agencyhome-header26">
                    <span className="footer-header">Contact</span>
                  </div>
                  <div className="agencyhome-links2">
                    <span className="footer-link">contact@agencymart.info</span>
                    <span className="footer-link">Phone: +91 7003405610</span>
                    {/* <span className="footer-link">News</span>
                    <span className="footer-link">Partners</span>
                    <span className="footer-link">Careers</span>
                    <span className="footer-link">Press &amp; Media</span> */}
                  </div>
                </div>
              </header>
            </div>
            <section className="agencyhome-copyright1">
              <span className="agencyhome-text66">
                © 2024. All Rights Reserved.
              </span>
            </section>
          </main>
          <main className="agencyhome-subscribe">
            <main className="agencyhome-main6 button open-modal-button">
              <h1
                className="agencyhome-heading28"
                // onClick={openModal}
              >
                Join AgencyMart
              </h1>
              <a href="/agencyonboarding/">
                <div className="agencyhome-input-field">
                  {/* <input
                  type="email"
                  placeholder="Enter your email"
                  className="agencyhome-textinput input"
                /> */}
                  <div className="agencyhome-buy4 button">
                    <span className="agencyhome-text67">-&gt;</span>
                    <span className="agencyhome-text68">
                      <button className="open-modal-button">Join now</button>
                      <br></br>
                    </span>
                  </div>
                </div>
              </a>
            </main>
            <h1
              className="agencyhome-notice open-modal-button"
              onClick={openModal}
            >
              Be part of our journey by joining AgencyMart.
            </h1>
          </main>
          <section className="agencyhome-copyright2">
            <span className="agencyhome-text71">
              © 2024. All Rights Reserved.
            </span>
          </section>
        </div>
      </footer>
      <div>
        {/* <div className="agencyhome-container6">
          <Script
            html={`<script>
            const listenForUrlChangesAccordion = () => {
                let url = location.href;
                document.body.addEventListener(
                  "click",
                  () => {
                    requestAnimationFrame(() => {
                      if (url !== location.href) {
                        runAccordionCodeEmbed();
                        url = location.href;
                      }
                    });
                  },
                  true
                );
              };
          
          const runAccordionCodeEmbed = () => {
              const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]');
              const accordionContents = document.querySelectorAll('[data-role="accordion-content"]');
              const accordionIcons = document.querySelectorAll('[data-role="accordion-icon"]');
          
              accordionContents.forEach((accordionContent) => {
                  accordionContent.style.display = "none";
              });
          
              accordionContainers.forEach((accordionContainer, index) => {
                  accordionContainer.addEventListener("click", () => {
                      accordionContents.forEach((accordionContent) => {
                      accordionContent.style.display = "none";
                      });
          
                      accordionIcons.forEach((accordionIcon) => {
                          accordionIcon.style.transform = "rotate(0deg)";
                      });
          
                      accordionContents[index].style.display = "flex";
                      accordionIcons[index].style.transform = "rotate(180deg)";
                  });
              });
          }
          
          runAccordionCodeEmbed()
          listenForUrlChangesAccordion()
</script>`}
          ></Script>
        </div> */}
      </div>
    </div>
  );
};

export default AgencyHome;
