import React from "react";

import PropTypes from "prop-types";

import "./faq.css";

const FAQ = (props) => {
  return (
    <div className={`faq-accordion ${props.rootClassName} `}>
      <div
        data-role="accordion-container"
        className="faq-element1 accordion-element"
      >
        <div className="faq-details1">
          <span className="faq-text10">
            How does AgencyMart vet agencies?
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span data-role="accordion-content" className="faq-text11">
            We conduct thorough background checks, review past work, and assess
            client feedback to ensure agencies meet our quality standards.
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon10">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element2 accordion-element"
      >
        <div className="faq-details2">
          <span className="faq-text12">
            Can I get help drafting my project requirements?
          </span>
          <span data-role="accordion-content" className="faq-text13">
            Yes! Our experts assist you in creating clear and effective project
            briefs to attract the best agencies.
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon12">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element3 accordion-element"
      >
        <div className="faq-details3">
          <span className="faq-text14">
            What if I need help choosing between proposals?
          </span>
          <span data-role="accordion-content" className="faq-text15">
            Our field experts are available to evaluate proposals and provide
            professional advice to help you make informed decisions.
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon14">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element4 accordion-element"
      >
        <div className="faq-details4">
          <span className="faq-text16">
            How much time does it take to find the right agency?
          </span>
          <span data-role="accordion-content" className="faq-text17">
            We strive to deliver results as quickly as possible. Typically, you
            can start receiving pitches from agencies within five days of
            submitting your requirements, ensuring a swift and efficient
            matchmaking process.
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon16">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element5 accordion-element"
      >
        <div className="faq-details5">
          <span className="faq-text18">
            Where can I raise concerns or get support?
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span data-role="accordion-content" className="faq-text19">
            If you have any concerns or need assistance, please reach out to us
            at contact@agencymart.info . Our dedicated support team is
            available to help you with any queries or issues you may encounter.
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon18">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element6 accordion-element"
      >
        <div className="faq-details6">
          <span className="faq-text20">
            What types of agencies are available on AgencyMart?
          </span>
          <span data-role="accordion-content" className="faq-text21">
            AgencyMart features a diverse network of agencies across various
            domains, including marketing, IT services, creative design, and
            more. Each agency is vetted for quality and expertise to meet your
            specific business needs.
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon20">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

FAQ.defaultProps = {
  rootClassName: "",
};

FAQ.propTypes = {
  rootClassName: PropTypes.string,
};

export default FAQ;
