// src/data/partnersData.js

export const partners = [
  {
    name: "Inc42 Datalabs",
    logo: "/PartnersLogos/datalabs_inc42.jpeg",
    url: "https://inc42.com/datalabs/",
  },
  {
    name: "Mesa School of Business",
    logo: "/PartnersLogos/mesa.png",
    url: "https://mesaschool.co/",
  },
  {
    name: "MPC Bangalore",
    logo: "/PartnersLogos/mpc.jpeg",
    url: "https://www.mumbaipav.co/",
  },
  {
    name: "Setu",
    logo: "/PartnersLogos/setu.png",
    url: "https://setu.co/",
  },
  {
    name: "Kaabil",
    logo: "/PartnersLogos/kaabil.png",
    url: "https://stage.kaabil.me/dashboard",
  },
  {
    name: "Iron Asylum",
    logo: "/PartnersLogos/ironasylum.png",
    url: "https://ironasylum.in/",
  },
  {
    name: "Increda Brew",
    logo: "/PartnersLogos/increda.jpeg",
    url: "https://www.incredabrew.com/",
  },
  // Add more partners as needed
];
