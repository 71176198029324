// src/data/partnersData.js

export const partners = [
  {
    name: "Techmagnet",
    logo: "/PartnersLogos/techmagnet.svg",
    url: "https://www.techmagnate.com/",
  },
  {
    name: "1702 Digital",
    logo: "/PartnersLogos/1702-digital.png",
    url: "https://1702digital.com/",
  },
  {
    name: "Vidyayatan",
    logo: "/PartnersLogos/vidyayatan.webp",
    url: "https://www.vidyayatan.com/",
  },
  {
    name: "Desun Technology",
    logo: "/PartnersLogos/desuntechnology.png",
    url: "https://www.desuntechnology.in/",
  },
  {
    name: "GeekyAnts",
    logo: "/PartnersLogos/geekyants.svg",
    url: "https://geekyants.com/",
  },
  {
    name: "Gushwork",
    logo: "/PartnersLogos/gushwork.svg",
    url: "https://www.gushwork.ai/",
  },
  {
    name: "Invent Colabs",
    logo: "/PartnersLogos/inventcolabs.svg",
    url: "https://www.inventcolabssoftware.com/",
  },
  {
    name: "Evon Technologies",
    logo: "/PartnersLogos/evontech.png",
    url: "https://evontech.com/",
  },
  {
    name: "Edelytics",
    logo: "/PartnersLogos/edelytics.png",
    url: "https://edelytics.com/",
  },
  {
    name: "Zuru Services LLP",
    logo: "/PartnersLogos/zuru-services.webp",
    url: "https://zuru.ai/",
  },
  {
    name: "Techuz Info",
    logo: "/PartnersLogos/techuz.avif",
    url: "https://techuz.com",
  },
  {
    name: "Thatware LLP",
    logo: "/PartnersLogos/thatware-llp.png",
    url: "https://thatware.co",
  },
  // Add more partners as needed
];
