import React, { useState } from "react";
import "./form.css";

const Form = ({ onSuccess }) => {
  const waitlist = "cm4i6jmyl00026j1mk8l1e622";
  const [email, setEmail] = useState("");
  const [name, setName] = useState(""); // Optional
  const [phone, setPhone] = useState(""); // Optional
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        "https://www.waitlist.email/api/subscribers/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            waitlist,
            email,
            name,
            phone,
          }),
        }
      );

      const body = await response.json();

      if (!response.ok) {
        throw new Error(body.message || "Something went wrong!");
      }

      window.alert("You have been subscribed!");
      // Optionally, reset the form fields after successful submission
      setEmail("");
      setName("");
      setPhone("");
      onSuccess(); // Close the modal
    } catch (error) {
      window.alert(error.message || "An error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="subscription-form" onSubmit={handleSubmit}>
      <h2>Join Our Waitlist</h2>
      <div className="form-group">
        <label htmlFor="email">
          Email<span className="required">*</span>
        </label>
        <input
          id="email"
          type="email"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your Email Id"
        />
      </div>

      <div className="form-group">
        <label htmlFor="name">Phone</label>
        <input
          id="phone"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your Phone Number"
        />
      </div>

      <div className="form-group">
        <label htmlFor="phone">Business Name and Requirement</label>
        <input
          id="phone"
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Enter your business Name and Requirement"
        />
      </div>

      <button type="submit" disabled={isLoading}>
        {isLoading ? "Submitting..." : "Submit"}
      </button>
    </form>
  );
};

export default Form;
